<template>
  <div class="wrapper">
    <span class="row-count"> Total managers: {{ rowCount }} </span>

    <div id="managers" class="managers">
      <DataTable
        :filters.sync="filterConfig"
        :loading="isLoading"
        :value="filteredManagers"
        :paginator="true"
        :rowHover="true"
        :rows="50"
        filterDisplay="row"
        responsiveLayout="scroll"
        dataKey="id"
        removableSort
        ref="datatable"
        @filter="updateRowCount"
      >
        <Column
          v-for="col in columns"
          :key="col.field"
          :bodyClass="col.bodyClass"
          :field="col.field"
          :header="col.header"
          :headerClass="col.headerClass"
          :sortable="col.sortable"
          :showFilterMenu="false"
          :showFilterOperator="false"
          :showFilterMatchModes="false"
          :showAddButton="false"
          :showClearButton="false"
          :showApplyButton="false"
        >
          <template v-if="col.field === 'office_id'" #body="slotProps">
            <span class="p-d-flex p-ai-center country-flag">
              <country-flag
                :country="getOffice(slotProps.data.office_id).code"
                size="small"
              />
              {{ getOffice(slotProps.data.office_id).name }}
            </span>
          </template>
          <template v-else-if="col.field === 'scope'" #body="slotProps">
            {{ getScope(slotProps.data.scope) }}
          </template>
          <template #filter>
            <Dropdown
              v-if="col.field === 'office_id'"
              v-model="filters[col.field]"
              :class="col.filterClass"
              :filter="true"
              :options="offices"
              appendTo="manage"
              dataKey="id"
              optionLabel="name"
              optionValue="id"
              placeholder=""
              showClear
              @input="filterManagers"
            ></Dropdown>
            <Dropdown
              v-else-if="col.field === 'scope'"
              v-model="filters[col.field]"
              :class="col.filterClass"
              :filter="true"
              :options="scopeOptions"
              appendTo="manage"
              dataKey="value"
              optionLabel="name"
              optionValue="value"
              placeholder=""
              showClear
              @input="filterManagers"
            ></Dropdown>
            <InputText
              v-else
              v-model="filters[col.field]"
              :class="col.filterClass"
              placeholder=""
              type="text"
              @input="filterManagers"
            />
          </template>
        </Column>

        <Column
          bodyClass="data-table-body data-table-body-action"
          headerClass="data-table-header data-table-header-action"
        >
          <template #body="slotProps">
            <router-link
              :to="'/manage/managers/' + slotProps.data.id"
              tag="button"
              class="action-button"
            >
              <Button
                class="p-button-rounded p-button-main p-button-sm manage-list-view-button"
                label="View"
              />
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { MemoryCache } from "ts-method-cache";
import { readManagers, readOffices } from "@/store/main/getters";
import {
  dispatchListManagers,
  dispatchListOffices,
} from "@/store/main/actions";
import "@/assets/styles/datatable.css";

@Component
export default class Managers extends Vue {
  public scopeOptions = [
    { name: "User", value: "user" },
    { name: "Manager", value: "manager" },
    { name: "Super Admin", value: "super" },
    { name: "Alpha", value: "alpha" },
  ];

  public columns = [
    {
      header: "ID",
      field: "id",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header data-table-header-short",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "First Name",
      field: "first_name",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Last Name",
      field: "last_name",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Email",
      field: "email",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Office",
      field: "office_id",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-filter-dropdown",
    },
    {
      header: "Department",
      field: "department",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Title",
      field: "title",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Permission",
      field: "scope",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-filter-dropdown",
    },
  ];

  public filterConfig = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    first_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    last_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    office: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    department: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    title: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    scope: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  public filteredManagers = [];
  public filters = {};
  public rowCount = 0;
  public isLoading = false;

  public get managers() {
    return readManagers(this.$store);
  }

  public get offices() {
    return readOffices(this.$store);
  }

  @MemoryCache()
  public getOffice(id) {
    if (!id) {
      return {};
    }
    const office = this.offices.find((item) => item.id === id);
    return office ?? {};
  }

  @MemoryCache()
  public getScope(scope) {
    if (!scope) {
      return "";
    }
    return this.scopeOptions.find(({ value }) => value === scope).name;
  }

  public updateRowCount(e) {
    this.rowCount = e.filteredValue.length;
  }

  public filterManagers() {
    let filtered = this.managers;
    for (const [key, value] of Object.entries(this.filters)) {
      if (key == "office_id" || key == "scope") {
        if (value !== null && value !== 0 && value !== "") {
          filtered = filtered.filter(
            (manager) => (manager[key] ?? "") === value
          );
        }
      } else if (value !== null && value !== "") {
        filtered = filtered.filter((manager) => {
          if (manager[key] !== null && manager[key] !== "") {
            return (manager[key] ?? "")
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase());
          }
          return false;
        });
      }
    }
    this.filteredManagers = filtered;
  }

  public async mounted() {
    window["analytics"]["page"]("/manage/managers");
    this.isLoading = true;
    await dispatchListOffices(this.$store);
    await dispatchListManagers(this.$store);
    this.filteredManagers = this.managers;
    this.rowCount = this.filteredManagers.length;
    this.isLoading = false;
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
}

.managers {
  height: calc(100vh - 130px);
  overflow: auto;
}

.country-flag {
  margin-left: -20px;
}
</style>
